<template>
    <v-card outlined class="elevation-1">
        <v-card-title class="grey lighten-5">
            <h5>Control {{ titulo }}</h5>
            <v-spacer />Total: S/.
            <h5>{{ total }}</h5>
        </v-card-title>
        <v-card-text class="grey lighten-5">
            <v-row v-for="(item, key) in items" :key="key" dense>
                <v-col cols="2" style="font-size: 10px">
                    <b>{{ item.id }}</b>
                </v-col>
                <v-col cols="8" class="text-left caption">Reserva</v-col>
                <v-col cols="2" class="caption">
                    S/.
                    <b>{{ item.importeAbonado }}</b>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    filters: {
        text(val) {
            if (val.length > 18) {
                return val.slice(0, 22).concat('...')
            }
            return val
        }
    },
    props: ['titulo', 'items', 'total', 'id']
}
</script>
